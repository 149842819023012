import { Link } from "gatsby"
import React from "react"
import Banner from "../../components/banner"
import { Button } from "../../components/button"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

/**
 * Support Form Page
 */

function SupportSuccessPage() {
  return (
    <>
      <Seo title="Technical Support - Request Received" />

      <Layout>
        <Banner label="Getting Help">Website &amp; Membership Support</Banner>

        <div className="container mx-auto px-3 py-6 lg:py-10">
          <p className="max-w-2xl text-xl mb-6">
            Thanks, we have received your enquiry and we will aim to come back
            to you within 48 hours.
          </p>

          <Link
            to="/"
            className="text-lg text-indigo-900 font-semibold underline"
          >
            Return to Homepage
          </Link>
        </div>
      </Layout>
    </>
  )
}

export default SupportSuccessPage
